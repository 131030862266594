<template>

    <CRow>
        <CCol>
            <CCard>
                <CCardHeader>
                    <strong> {{isUpdate ? '商品詳細' : '商品登録'}}</strong>
                </CCardHeader>
                <vue-confirm-dialog></vue-confirm-dialog>
                <CCardBody>
                    <CForm>
                        <CRow>
                            <CCol sm="4">
                                <CInput
                                        id="Name"
                                        label="品名"
                                        horizontal
                                        v-model="formData.name"
                                        type="text"
                                        :invalid-feedback="errors && errors.name ? errors.name[0] : ''"
                                        :is-valid="errors && errors.name ? false : null"
                                />
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="size" label="サイズ" end-point="sizes" @change="changeSize"
                                                 :default-value="formData.size_id" placeholder="選択する"
                                                 :isError="errors && errors.size_id ? errors.size_id[0] : ''"
                                />
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="color" label="色" :default-options="colorOptions" @change="changeColor"
                                                 :default-value="formData.color_id" placeholder="選択する"
                                                 :isError="errors && errors.color_id ? errors.color_id[0] : ''"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CInput
                                        id="Cost"
                                        label="仕入原価"
                                        horizontal
                                        v-model="formData.cost"
                                        type="number"
                                        min="0"
                                        :invalid-feedback="errors && errors.cost ? errors.cost[0] : ''"
                                        :is-valid="errors && errors.cost ? false : null"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CInput
                                        id="Price"
                                        label="販売価格"
                                        horizontal
                                        v-model="formData.price"
                                        type="number"
                                        min="0"
                                        :invalid-feedback="errors && errors.price ? errors.price[0] : ''"
                                        :is-valid="errors && errors.price ? false : null"
                                />
                            </CCol>
                            <CCol sm="4">
                                <CInput
                                        id="Retailprice"
                                        label="プロパー上代"
                                        horizontal
                                        v-model="formData.retail_price"
                                        type="number"
                                        min="0"
                                        :invalid-feedback="errors && errors.retail_price ? errors.retail_price[0] : ''"
                                        :is-valid="errors && errors.retail_price ? false : null"
                                />
                            </CCol>
                            <CCol sm="4">
                              <SelectComponent id="ppbs" label="PP/BS区分" :default-options="ppbsOptions" @change="changePPBS"
                                               :default-value="formData.pp_bs_code"
                                               placeholder="選択する"
                                               :isError="errors && errors.pp_bs_code ? errors.pp_bs_code[0] : ''"
                              />
                            </CCol>
                            <CCol sm="4">
                                <CInput
                                        id="Itemcode"
                                        label="品番コード"
                                        horizontal
                                        v-model="formData.item_code"
                                        type="text"
                                        :invalid-feedback="errors && errors.item_code ? errors.item_code[0] : ''"
                                        :is-valid="errors && errors.item_code ? false : null"
                                />
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="season" label="シーズン" end-point="seasons" @change="changeSeasons"
                                                 :default-value="formData.season_id" placeholder="選択する"
                                                 :isError="errors && errors.season_id ? errors.season_id[0] : ''"/>
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="month" label="展開月" :default-options="businessMonthOptions"
                                                 @change="changeBusinessMonth" :default-value="formData.month"
                                                 placeholder="選択する"
                                                 :isError="errors && errors.month ? errors.month[0] : ''"/>
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="year" label="年度" :default-options="businessYearOptions"
                                                 @change="changeBusinessYear" :default-value="formData.business_year"
                                                 placeholder="選択する"
                                                 :isError="errors && errors.business_year ? errors.business_year[0] : ''"/>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol sm="4">
                                <SelectComponent id="majorcategory" label="大分類" end-point="major-categories" @change="changeMajorCategory"
                                                 :default-value="formData.major_category_id" placeholder="選択する"
                                                 :isError="errors && errors.major_category_id ? errors.major_category_id[0] : ''"
                                />
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="middlecategory" label="中分類" end-point="middle-categories" @change="changeMiddleCategory"
                                                 :default-value="formData.middle_category_id" placeholder="選択する"
                                                 :isError="errors && errors.middle_category_id ? errors.middle_category_id[0] : ''"
                                />
                            </CCol>
                            <CCol sm="4">
                                <SelectComponent id="minorcategory" label="小分類" end-point="minor-categories" @change="changeMinorCategory"
                                                 :default-value="formData.minor_category_id" placeholder="選択する"
                                                 :isError="errors && errors.minor_category_id ? errors.minor_category_id[0] : ''"
                                />
                            </CCol>
                        </CRow>
                    </CForm>
                </CCardBody>
                <CCardFooter>
                    <CButton @click="handleConfirm"   type="submit" color="success">
                        <CIcon name="cil-check-circle"/>
                      {{ isUpdate ? '更新する' : '登録する' }}
                    </CButton>
                </CCardFooter>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import ResourceApi from '../../api/resourceApi'
    import SelectComponent from "@/components/views/SelectComponent";
    import {setOptionBusinessMonth, setOptionBusinessYear, setOptionColors} from "@/utils/utils";
    import Vue from "vue";

    const apiItemMasters = new ResourceApi('item-masters');

    export default {
        name: 'CreateItemMaster',
        components: { SelectComponent },
        props: {
          isUpdate: {
            type: Boolean,
            default: false
          }
        },
        data() {
            return {
              formData: {
                name: '',
                price: '',
                cost: '',
                pp_bs_code: '',
                retail_price: '',
                item_code: '',
                month: '',
                business_year: '',
                season_id: '',
                color_id: '',
                updateApi: '',
                size_id: '',
                major_category_id: '',
                middle_category_id: '',
                minor_category_id: '',
              },
              id:this.$route.params.id,
              businessYearOptions: [],
              businessMonthOptions: [],
              colorOptions: [],
              key: Math.random(),
              errors: {},
              ppbsOptions: [
                {label: 'PP', value: 'PP'},
                {label: 'BS', value: 'BS'},
              ],
            }
        },
        created() {
            this.businessYearOptions = setOptionBusinessYear();
            this.businessMonthOptions = setOptionBusinessMonth();
            this.colorOptions = setOptionColors();
            this.getData();
        },

        methods: {
            changeSeasons(val) {
                this.formData.season_id = val
            },
            changeSize(val) {
                this.formData.size_id = val
            },
            changeColor(val) {
                this.formData.color_id = val
            },
            changeBusinessYear(val) {
                this.formData.business_year = val
            },
            changeBusinessMonth(val) {
                this.formData.month = val
            },
            changeMajorCategory(val) {
                this.formData.major_category_id = val
            },
            changeMiddleCategory(val) {
                this.formData.middle_category_id = val
            },
            changeMinorCategory(val) {
                this.formData.minor_category_id = val
            },
            changePPBS(val) {
              this.formData.pp_bs_code = val
            },

            handleConfirm(){
                this.$confirm(
                    {
                        title: '確認',
                        message: this.isUpdate ? '商品更新を行います。宜しいですか？' : '入荷登録を行います。宜しいですか？',
                        button: {
                            no: 'いいえ',
                            yes: 'はい'
                        },
                        callback: confirm => {
                            if (confirm) {
                                // ... do something
                                this.createItemMaster()
                            }
                        }
                    }
                )
            },
            createItemMaster() {
              const id = this.$route.params.id
              this.updateApi = apiItemMasters.create;
              if(id){
                this.updateApi = apiItemMasters.edit;
              }
              this.updateApi(this.formData).then(response => {
                  let message = '登録成功しました。'
                  if (id) {
                    message = '更新成功しました。'
                  }
                  Vue.$toast.success(message)
                  setTimeout(this.goScreenList, 2000);
                }).catch(error => {
                    let dataErrors = error.response?.data?.errors || ''
                    this.errors = dataErrors;
                    // console.log(11111, dataErrors)
                    // Vue.$toast.error('Validate error')
                }).finally(() =>{
                    this.loading = false
                })
            },
            getData () {
              const id = this.$route.params.id
              if(!id){
                return
              }
              apiItemMasters.show({id: id}).then(response => {
                // console.log(response.data)
                let data =  response.data;
                data.id = this.$route.params.id
                data.business_year = parseInt(data.business_year)
                this.formData = data;
                this.loading = false
                this.key = Math.random()
              }).catch(error => {
                this.loading = false
              })
            },
            goScreenList() {
                this.$router.back()
            },

          },
    }
</script>

